
import React, {  useEffect, useState } from 'react'
import { ProjectService } from '../../services/ProjectService'

import './Projects.scss'
import LoaderCmp from '../../cmps/LoaderCmp/LoaderCmp';
import ProjectList from '../../cmps/ProjectList/ProjectList';
import TitlePage from '../../cmps/TitlePage/TitlePage';
import Filter from '../../cmps/Filter/Filter';
import ErrorSearch from '../../cmps/ErrorSearch/ErrorSearch';
const Projects = () => {
    const [projects,setProjects] = useState([])
    const [filterBy,setFilterBy] = useState(null)
    
    useEffect(() => {
       const projectsLoad = ProjectService.loadProjects(filterBy)
        setProjects(projectsLoad)
    }, [filterBy])

   const handleFilter = ({target})=>{
      const {value} =target
      setFilterBy(value)
   }


  
  if(!projects) return <LoaderCmp />
    return (
        <section className="projects">
            <TitlePage>Projects</TitlePage>
            <Filter filterBy={filterBy} projectsNames={projects}  setFilter={handleFilter}></Filter>
            <div className="container">
           {projects.length <=0 ?  <ErrorSearch />  :  <ProjectList projects={projects} /> }
            </div>
        </section>
    )

   
}

export default Projects
