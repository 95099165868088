
import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import './Filter.scss'
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

const Filter = ({setFilter,filterBy}) => {
    const [open, setOpen] = useState(false);
    const items = [{value:'all',label:'All'},{value:'vue',label:'Vue'},{value:'react',label:'React'},{value:'angular',label:'Angular'},{value:'web design',label:'Web Design'}]
    const classes = useStyles();
    
  
    return (
        <section className="filter flex center-center">
             <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={()=>setOpen(false)}
          onOpen={()=>setOpen(true)}
          onChange={setFilter}
         value={filterBy ?? ''}
        >
            {items.map((item,idx)=><MenuItem key={idx} value={item.value}>{item.label}</MenuItem>)}
         
        </Select>
      </FormControl>
        </section>
    )

   
}

export default Filter
