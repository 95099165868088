
import React from 'react'

import './Contact.scss'
import SocialMedia from '../SocialMedia/SocialMedia';
import FontIcon from '../FontIcon/FontIcon';

const Contact = (props) => {

    return (
        <section className="contact margin-bottom">
        <h2 className="text-center margin-bottom">Get in Touch!</h2>
        <div className="contact-container container flex column center-center">
            <p className="margin-bottom text-center">I’m currently looking for a Full-Stack/Front-End Development role, please contact me by Email or by any of the social medias below. </p>
        <SocialMedia />
        <div className="button-control margin-top">

           <button onClick={()=>window.location.href = 'mailto:guy235@gmail.com?subject=New Connection from the site&body=Hi Guy,I come from your website and i would like to contact with you!'} className="btn-cta ">Get in Touch <FontIcon fontClass='fas fa-envelope' /></button> 
        </div>
        </div>
        </section>
    )

   
}

export default Contact
