import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './styles/_style.scss';
import Header from './cmps/Header/Header';
import Footer from './cmps/Footer/Footer';

import RouterView from './cmps/RouterView/RouterView';

function App() {
  return (
    <div className="App">
   <Router>
     <Header />
   <main>
     <RouterView />
   </main>
     <Footer />
   </Router>
    </div>
  );
}

export default App;
