
import React from 'react'

import './Footer.scss'
import SocialMedia from '../SocialMedia/SocialMedia';
import logo from '../../logo.svg'


const Footer = (props) => {

    return (
        <footer className="footer  flex column center-center padding-bottom">
        <SocialMedia></SocialMedia>

        <small className="copy-rights">   Guy Indepurker 2020 All the rights saved &copy;
</small>
<img src={logo} alt="logo" className="logo" />
        </footer>
    )

   
}

export default Footer
