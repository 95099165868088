
import React from 'react'

import './Logo.scss'
import logo from '../../logo.svg'
const Logo = (props) => {

    return (
        <div className="logo flex align-center ">
        <div className="logo-txt">GuyIndepurker<span>.com</span></div>
       <img src={logo} alt="logo" className="logo-img"/>
    </div>
    )

   
}

export default Logo
