const skills = [{ label: 'React Redux', icon: 'fab fa-react' }, { label: 'Vue Vuex', icon: 'fab fa-vuejs' }, { label: 'Angular', icon: 'fab fa-angular' }, { label: 'NodeJs', icon: 'fab fa-node' }, { label: 'JavaScript', icon: 'fab fa-js' }, { label: 'CSS3', icon: 'fab fa-css3' }, { label: 'SASS', icon: 'fab fa-sass' }, { label: 'HTML5', icon: 'fab fa-html5' }, { label: 'MONGODB / SQL / MONGOOSE', icon: 'fas fa-database' }, { label: 'WORDPRESS / PHP', icon: 'fab fa-wordpress' }, { label: 'Github', icon: 'fab fa-github' }, { label: 'NPM', icon: 'fab fa-npm' }]
export const UtilService={
    skills,
    findKeyWord,
    buildFilter
}
function findKeyWord(value,regex) {
    value = value.toLowerCase()
    if(value.search(regex)!==-1){
        return value;
    }
}
function buildFilter(filterBy){
    filterBy = filterBy.toLowerCase()
   const filter = filterBy === 'web design' ? 'html' : filterBy
   return filter
}