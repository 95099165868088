
import React from 'react'

import {Route, Switch } from 'react-router-dom';
import { Routes } from '../../services/routes';

const RouterView = () => {

    return (
        <>
            <Switch>
        {Routes.map((route,key)=><Route path={route.path} component={route.component} key={key} />)}
            </Switch>
        
        </>
    )

   
}

export default RouterView
