
import React from 'react'

import './Strong.scss'

const Strong = (props) => {

    return (
        <span className="strong">
        {props.children}
        </span>
    )

   
}

export default Strong
