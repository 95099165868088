
import React from 'react'

import './LoaderCmp.scss'

const LoaderCmp = (props) => {

    return (
        <div className="loader-container flex center-center"><div className="loader-cmp"></div></div>
    )

   
}

export default LoaderCmp
