
import React from 'react'

import './BackDropLayer.scss'

const BackDropLayer = ({isOpen,closeFunc}) => {
    if(!isOpen) return null
    return (
      <section onClick={closeFunc} className="back-drop-layer">
       
        </section>
    )

   
}

export default BackDropLayer
