
import React from 'react'
import Particles from 'react-particles-js';
import img from '../../assets/imgs/guy.png'
import './HomePage.scss'
import Contact from '../../cmps/Contact/Contact';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const HomePage = (props) => {
    const isMobile = useMediaQuery('(max-width:550px)')
    const sendToPath = path => {
        props.history.push(path)
    }
    const getValue = () => {
        return isMobile ? 95 : 200
    }
    const Hero = () => {
        return (<div className="hero container flex column  margin-bottom">
            <div className="hero-main flex space-between">
                <div className="animate__animated animate__fadeInLeftBig hero-content flex column justify-center margin-right">
                    <h2 className="txt-tiny margin-bottom">Hi, my name is</h2>
                    <h2 className="txt-name">Guy Indepurker.</h2>
                    <h1 className="txt-role">Full Stack Developer </h1>
                    <p className="txt-paragraph">I’m a Full Stack Developer, specialized building single web page applications end to end from scratch using the latest modern frameworks and technologies.</p>
                </div>
                <div className="hero-img  animate__animated animate__fadeInRight">
                    <img src={img} alt="Guy Indepurker " />
                </div>
            </div>
            <div className="button-control animate__animated animate__fadeIn flex justify-center margin-top margin-bottom">
                <button onClick={() => sendToPath('/about')} className="margin-right btn-cta">About Me <i className="fas fa-user"></i></button>
                <button onClick={() => sendToPath('/projects')} className="btn-cta arrow">See projects</button>
            </div>
            <div id="contact-us" className="contact-home-page margin-top animate__animated animate__fadeInUpBig">
                <Contact></Contact>
            </div>
        </div>)
    }
    return (
        <section className="home-page  padding-bottom flex column center-center ">
            <Hero />

            <Particles className="bg-particles"  width="100%" style={{
                opacity: 0.5
            }} params={{
                "particles": {
                    "number": {
                        "value": getValue()
                    },
                    "size": {
                        "value": 3
                    }
                },
                "interactivity": {
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "repulse"
                        }
                    }
                }
            }} />
        </section>
    )


}

export default HomePage
