import { UtilService } from './UtilService';
const {projects:gProjects} = require('./projects.json')
export const ProjectService = {
loadProjects,   
}

function loadProjects(filterBy){
    return query(filterBy)
}

function query(filterBy) {
    if(!filterBy || filterBy==='all') return gProjects
    return filterProjects(filterBy)
}

function filterProjects(filterBy) {
    let filter = UtilService.buildFilter(filterBy)
    const projects = [...gProjects]
    const filteredProjects = projects.filter(project=>project.skills.some(skill=> UtilService.findKeyWord(skill,filter)))
    return filteredProjects
}