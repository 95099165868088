
import React from 'react'

import './SocialMedia.scss'
import FontIcon from '../FontIcon/FontIcon';

const SocialMedia = (props) => {
    const socialIcons = [{class:'fab fa-linkedin',url:'https://www.linkedin.com/in/guy-indepurker-5778091a4/'},{class:'fab fa-github-square',url:'https://github.com/guyindepurker'},{class:'fas fa-envelope',url:'mailto:guy235@gmail.com'},{class:'fas fa-phone-square-alt',url:'tel:0502880360'}]
    return (
        <ul className="clean-list flex social-media">
            
            {socialIcons.map((icon,key)=><a key={key} className="social-link" href={icon.url}><FontIcon key={key} fontClass={icon.class} /></a>)}
        </ul>
    )

   
}

export default SocialMedia
