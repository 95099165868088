
import React from 'react'

import './TitlePage.scss'

const TitlePage = (props) => {

    return (
        <div className="animate__animated animate__fadeInDown title-page flex center-center margin-bottom">
            <div className="line"></div>
            <h1 className="header-primary">{props.children}</h1>
            <div className="line"></div>
        </div>
    )

   
}

export default TitlePage
