
import React from 'react'

import './FontIcon.scss'

const FontIcon = ({ fontClass, onClick }) => {
    if (onClick) return (<i onClick={onClick} className={`font-icon ${fontClass}`}>

    </i>)
    return (
        <i className={`font-icon ${fontClass}`}>

        </i>
    )


}

export default FontIcon
