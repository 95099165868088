
import React from 'react'

import './Imgs.scss'
const Imgs = (props) => {
    const numbers = [2,3,4,5,6]
    const imgs = numbers.map(num=>{
        const img = require(`../../assets/imgs/guy${num}.png`)
        return img.default
})
    return (
        <section className="imgs flex center-center">
            
        <ImgList imgs={imgs}></ImgList>
        </section>
    )

   
}

export default Imgs
const ImgPreview = ({img}) =>{
    return (
        <li className="img-preview">
            <img src={img}  alt="guy"/>
        </li>
    )
}
const ImgList = ({imgs}) =>{
    return(       
     <ul className="clean-list img-list flex wrap center-center flex-gap">
         {imgs.map(img=><ImgPreview key={img} img={img}></ImgPreview>)}
    </ul>
)
}

