
import React,{useState} from 'react'
import {NavLink,Link} from 'react-router-dom'
import './Header.scss'

import useMediaQuery from '@material-ui/core/useMediaQuery';
import BackDropLayer from '../BackDropLayer/BackDropLayer';
import FontIcon from '../FontIcon/FontIcon';
import Logo from '../Logo/Logo';

const Header = (props) => {
const links = [{txt:'Home',src:'/'},{txt:'About',src:'/about'},{txt:'Projects',src:'/projects'}]
const isMobile = useMediaQuery('(max-width:550px)') 
const [openNav,toggleNav] = useState(false)  
const handleNavMobile = (ev)=>{
    ev.preventDefault()
    toggleNav(!openNav)
} 
const Menu = () =>{
    return (
        <ul className={`nav-menu clean-list flex ${isMobile&& openNav && 'open-nav'}`}>
        {isMobile ? links.map((link,idx)=><li key={idx} onClick={handleNavMobile} className="nav-link"><NavLink exact to={link.src}>{link.txt}</NavLink></li>) : links.map((link,idx)=><li key={idx} className="nav-link"><NavLink exact to={link.src}>{link.txt}</NavLink></li>)}
        </ul>
    )
}
return (
    <>
        <header className="header">
        <nav className="flex container space-between">
        <Link className="link-logo" to="/">
             <Logo />
            </Link>
            <FontIcon onClick={()=>toggleNav(!openNav)} fontClass={`hamburger fas fa-${openNav ? 'times' : 'bars'}`} />
           <Menu />
        </nav>
        </header>
        <BackDropLayer isOpen={openNav} closeFunc={()=>toggleNav(false)} />
        </>
    )

   
}

export default Header
