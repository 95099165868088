
import React from 'react'

import './About.scss'
import guyImg from '../../assets/imgs/guy1.jpg'
import TitlePage from '../../cmps/TitlePage/TitlePage';
import FontIcon from '../../cmps/FontIcon/FontIcon';
import Strong from '../../cmps/Strong/Strong';
import { UtilService } from '../../services/UtilService';
import Imgs from '../../cmps/Imgs/Imgs';
import SocialMedia from '../../cmps/SocialMedia/SocialMedia';
const About = (props) => {
    const skills = UtilService.skills
    const Paragraph = props => {
        return (
            <p className="about-p">
                {props.children}
            </p>
        )
    }

    return (
        <section className="about">
            <TitlePage>About me</TitlePage>
            <div className="about-container flex wrap center-center container ">
                <div className="about-content flex column">
                    <Paragraph>
                        My name is Guy Indepurker, I’m 24 years old <Strong>Full Stack Developer</Strong> from Israel. I’m currently looking for my first role, I have a big passion for programming and creating web application end to end from scratch using the latest web technologies and frameworks. I’m an autodidact serious and hardworking person, paying attention the small details, and big picture thinker. I’m a quick learner, and a great team player.
                </Paragraph>
                    <SocialMedia></SocialMedia>
                </div>
                <div className="img-container">
                    <img src={guyImg} alt="Guy" className="img-about" />
                </div>
            </div>

            <Imgs></Imgs>


            <div className="container my-skills flex column center-center ">
                <TitlePage>My Skills</TitlePage>
                <div className="icon-container margin-top flex wrap center-center">
                    {skills.map((skill, idx) => <span key={idx} className="flex center-center"><FontIcon key={idx} fontClass={skill.icon} /></span>)}</div>
            </div>


        </section>
    )


}

export default About
