import About from '../views/About/About';
import Projects from '../views/Projects/Projects';
import HomePage from '../views/HomePage/HomePage';

export const Routes = [
    {
        path:'/projects',
        'component':Projects
    },
    {
        path:'/about',
        'component':About
    },
    {
        path:'/',
        'component':HomePage
    }

]
