
import React from 'react'

import './ProjectList.scss'
import ProjectPreview from '../ProjectPreview/ProjectPreview';

const ProjectList = ({projects}) => {

    return (
        <ul className="clean-list project-list flex justify-center wrap">
            {projects.map(project=><ProjectPreview project={project} key={project.id} />)}
    
        </ul>
    )

   
}

export default ProjectList
