
import React from 'react'
import Lottie from 'react-lottie';
import errLottie from '../../assets/lottie/errorLottie.json'
import './ErrorSearch.scss'

const ErrorSearch = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: errLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <section className="error-search flex column center-center flex-gap">
            <Lottie options={defaultOptions}
                height={250}
                width={250}></Lottie>
            <p>Sorry we cant find match. try something else!</p>

        </section>
    )


}

export default ErrorSearch
