
import React,{ useState } from 'react'
import './ProjectPreview.scss'
// import { UtilService } from '../../services/UtilService';
// import FontIcon from '../FontIcon/FontIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const ProjectPreview = ({project}) => {
    const img = require(`../../assets/imgs/projects/${project.title}.png`)
   
const skills = project.skills.join()
const isMobile = useMediaQuery('(max-width:550px)') 
const [isHoverProject,setIsHoverProject] = useState(false)
const goToGit = (ev,github) =>{
    ev.stopPropagation()
    window.open(github,'_blank')
}

    return (isMobile ? <ProjectMobile project={project} imgUrl={img.default} skills={skills} goToGit={goToGit}  /> :
        <li  onMouseLeave={()=>setIsHoverProject(!isHoverProject)} onMouseEnter={()=>setIsHoverProject(!isHoverProject)} onClick={(()=>window.open(project.url,'_blank'))} className={`project-preview flex align-center column ${isHoverProject && 'active' }`}>
            <div className="img-container">

                <img src={img.default} className="project-img" alt="img project" />
            </div>
          
            <div className="project-content  flex text-center column margin-bottom">
                <h2 className="project-title ">{project.title}</h2>
                <p className="project-description ">{project.description}</p>
                <h4 className="project-skills">{skills}</h4>
            </div>
           { isHoverProject&&<div className="button-control flex center-center "> 
            <button className="margin-right btn-project">Go to project</button>
             {project.github &&  <button onClick={(ev)=>goToGit(ev,project.github)} className="btn-git"> <i className="fab fa-github"></i></button>}</div>}
        </li>
    )

   
}

export default ProjectPreview
const ProjectMobile = ({project,goToGit,imgUrl,skills}) => {
return (
    <li  onClick={(()=>window.open(project.url,'_blank'))} style={{backgroundImage:`url(${imgUrl})`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}} className="project-preview flex align-center column">
  
    <div className="project-content  flex text-center column margin-bottom">
        <h2 className="project-title ">{project.title}</h2>
        <p className="project-description ">{project.description}</p>
        <h4 className="project-skills">{skills}</h4>
    </div>
  <div className="button-control flex center-center "> 
    <button className="margin-right btn-project">Go to project</button>
    <button onClick={(ev)=>goToGit(ev,project.github)} className="btn-git"> <i className="fab fa-github"></i></button></div>
</li>
)
}